// @ts-nocheck
// import { Rubik } from 'next/font/google';
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFootball, faBars, faDownLong } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  FormControl,
  TextField,
  ThemeProvider,
  createTheme,
  Divider,
  Button,
  AppBar,
  Toolbar,
  Container,
  MenuItem,
  Typography,
  Menu,
  Box,
  Snackbar,
  Alert
} from '@mui/material';
import { ReactNode, useState, Suspense } from 'react';
// import localFont from "@next/font/local";
// import { NextFont } from "@next/font";
// import { Alert } from '@material-ui/lab'

// const customFont: NextFont = localFont({
//   src: "../public/fonts/Rubik-VariableFont_wght.ttf",
//   display: "swap",
// });

// const roboto = Rubik({
//   weight: '400',
//   subsets: ['latin'],
// });



const theme = createTheme({
  typography: {
    fontFamily: 'Rubik',
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    text: {
      secondary: 'white', // Set the secondary text color
    },
  },
  palette: {
    primary: {
      main: '#2c45a3',
    },
  },
});

const LearnMoreButton = ({ children, onClick = () => { } }: { children?: ReactNode, onClick?: () => void }) =>
  <Button onClick={onClick} variant="contained" type="submit" style={{ backgroundColor: '#ff5c35', color: 'white', borderRadius: 25, fontSize: 28 }}>Learn More{children}</Button>

export default function Home() {
  // const classes = useStyles();
  // const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [formValues, setFormValues] = useState({})
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  // const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const handleEmailChange = (event) => {
    // const inputEmail = event.target.value;
    // update state
    updateFormValue(event);

    // Email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the input matches the email pattern
    const isValidEmail = emailRegex.test(event.target.value);

    // Set the error state based on the validation result
    setError(!isValidEmail);
  };

  const updateFormValue = (e) => {
    const newFormValues = { ...formValues }
    newFormValues[e.target.name] = e.target.value

    setFormValues(newFormValues)
  }

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const scrollToItemOnPage = (id: string, isDrawerOpen: boolean) => {
    if (isDrawerOpen) {
      document.getElementById(id)
      handleDrawerToggle()

      setTimeout(() => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
      }, 100)

      return;
    }

    document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
  }

  const menuOptions = (
    <div
      style={{
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => scrollToItemOnPage('about', isDrawerOpen)}>
        About
      </Typography>
      {/* <Typography variant="h6" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => scrollToItemOnPage('testimonial', isDrawerOpen)}>
        Testimonal
      </Typography> */}
      <Typography variant="h6" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => scrollToItemOnPage('how-it-works', isDrawerOpen)}>
        How It Works
      </Typography>
      <Typography variant="h6" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => scrollToItemOnPage('learn-more', isDrawerOpen)}>
        Learn More
      </Typography>
    </div>
  );

  const drawerMenu = (
    <div
      style={{
        width: 200,
      }}
    >
      <List>
        <ListItem button>
          <ListItemText primary="About" onClick={() => scrollToItemOnPage('about', isDrawerOpen)} />
        </ListItem>
        {/* <ListItem button>
          <ListItemText primary="Testimonal" onClick={() => scrollToItemOnPage('testimonial', isDrawerOpen)} />
        </ListItem> */}
        <ListItem button>
          <ListItemText primary="How It Works" onClick={() => scrollToItemOnPage('how-it-works', isDrawerOpen)} />
        </ListItem>
        <ListItem button onClick={() => scrollToItemOnPage('learn-more', isDrawerOpen)}>
          <ListItemText primary="Learn More" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div
      style={{
        fontFamily: 'Rubik'
      }}
    >
      <ThemeProvider theme={theme}>
        <AppBar
          position="static"
          style={{
            top: 0,
            position: 'sticky',
            zIndex: theme.zIndex.appBar,
          }}
        >
          <Toolbar>
            {isMobile ? (
              <>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle}
                >
                  <FontAwesomeIcon icon={faBars} />
                </IconButton>
                <Drawer
                  anchor="right"
                  open={isDrawerOpen}
                  onClose={handleDrawerToggle}
                >
                  {drawerMenu}
                </Drawer>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faFootball} />
                {menuOptions}
              </>
            )}
          </Toolbar>
        </AppBar>
        <div
          style={{
            width: '100%',
            background: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div
            style={{ width: '90%' }}
          >

            {/* <div
                style={{
                  maxWidth: 675,
                  margin: 10,
                  marginBottom: 0,

                }}
              >
                <div
                  className={roboto.className}
                  style={{
                    // fontFamily: 'montserrat',
                    fontSize: 58,
                    padding: 0,
                    color: '#2c45a3',
                    fontWeight: 'bold',
                    // textAlign: 'center',
                    marginBottom: 10,
                    textAlign: 'left',
                  }}
                >
                  <FontAwesomeIcon icon={faFootball} color='#2c45a3' size='sm' /> {'Instant Play Design'.toUpperCase()}
                </div>
                <SubText>
                  Real Time Play Design. Diagram what you see from the box to the field in real time.
                </SubText>
              </div> */}

            {
              isMobile
                ? <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '',
                    paddingBottom: '10%'
                  }}
                >
                  <div
                    style={{
                      display: 'inline-block',
                      borderRadius: '50%',
                      // boxShadow: '0 0 2px #888',
                      padding: '30px',
                      backgroundColor: '#2c45a3'
                    }}
                  >
                    <FontAwesomeIcon icon={faFootball} color='white' size="4x" />
                  </div>
                  <div
                    style={{
                      // fontFamily: 'montserrat',
                      fontSize: 58,
                      // padding: 0,
                      color: '#2c45a3',
                      fontWeight: 'bold',
                      // textAlign: 'center',
                      // marginBottom: 10,
                      textAlign: 'center',
                      marginTop: 15,
                      marginBottom: 10,
                      // lineHeight: 1.3
                    }}
                  >
                    {'Instant Play Design'.toUpperCase()}
                  </div>
                  <SubText center>
                    <div>
                      Diagram from the box to the field in real time.
                    </div>
                  </SubText>
                  <br />
                  <LearnMoreButton onClick={() => scrollToItemOnPage('learn-more', isDrawerOpen)}><FontAwesomeIcon icon={faDownLong} color="white" style={{ marginLeft: 10 }} /></LearnMoreButton>
                </div>
                : <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '',
                    paddingBottom: '15%'
                  }}
                >
                  <div
                    style={{
                      display: 'inline-block',
                      borderRadius: '50%',
                      padding: '30px',
                      backgroundColor: '#2c45a3'
                    }}
                  >
                    <FontAwesomeIcon icon={faFootball} color='white' size="6x" />
                  </div>
                  <div
                    style={{
                      fontSize: 58,
                      color: '#2c45a3',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: 25,
                      marginBottom: 10
                    }}
                  >
                    {'Instant Play Design'.toUpperCase()}
                  </div>
                  <SubText center>
                    <div>
                      Diagram what you see from the box to the field in real time.
                    </div>
                  </SubText>
                  <br />
                  <LearnMoreButton onClick={() => scrollToItemOnPage('learn-more', isDrawerOpen)}><FontAwesomeIcon icon={faDownLong} color="white" style={{ marginLeft: 10 }} /></LearnMoreButton>
                </div>
            }


            <Section
              id="about"
              header="It's this easy"
              subtext={<div
                style={{
                  // width: "90%",
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                Create live diagrams on any tablet, phone, or computer that anyone can view and engage with on another device.
                <br />
                <br />
                <img style={{
                  width: '100%',
                  height: 'auto',
                  display: 'block',
                  marginTop: 20,
                    marginBottom: 20,
                  // borderRadius: 30,
                  maxWidth: 1000

                }} src="https://football-play-design-frontend.s3.us-west-1.amazonaws.com/diagram.png" alt="Image" />
              </div>}
            />



            <Section
              id="how-it-works"
              header="How it works"
              subtext={<>
                Allow your coaches in the box to diagram plays and schemes directly with your coaches on the sideline in real time.
                <div style={{
                  maxWidth: '100%',
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  {/* <div style={{
                    maxWidth: '100%',
                    height: 'auto',
                    marginTop: 20,
                    marginBottom: 20
                  }}> */}
                    <img style={{
                      width: '100%',
                      height: 'auto',
                      display: 'block',
                      borderRadius: 30,
                      marginTop: 20,
                    marginBottom: 20,
                    maxWidth: 1000
                    }} src="https://football-play-design-frontend.s3.us-west-1.amazonaws.com/Coach+Box+(1).jpg" alt="Image" />
                  {/* </div> */}
                </div>
                {`No more trying to describe to the rest of the staff what you're seeing. Show them in real time with diagrams that will help your team win.
                  Share a link. Have one coach on the sideline with a tablet. Have another coach in the box with a phone or tablet.`}
              </>}

            />

            {/* <Section
                header="It's this easy"
                subtext="Show graphic or video of demonstration"
              /> */}

            {/* <Section
                id="testimonial"
                header="Testimonial"
                subtext={
                  <div
                    style={{
                      width: '100%',
                      fontStyle: 'italic'
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'center'
                      }}
                    >
                      {"Instant play design helped our coaches clear up confusion in the middle of the game."}
                      <br />
                      <br />
                      {"Our communication changed for the better and helped our make adjustments more easily."}
                      <br />
                      <br />
                    </div>
                    - Jackson McAllister, Head Coach, Farview High School
                  </div>
                }
              /> */}

            <Section
              id="learn-more"
              header='Learn More'
              subtext={
                <div
                  style={{
                    width: '100%'
                  }}
                >
                  <SubText>
                    Submit your info, and someone from our team will reach out to tell you more.
                  </SubText>

                  <div
                    style={{
                      marginTop: 30,
                      width: '100%',
                      marginBottom: 100,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <div
                      style={{
                        width: '80%'
                      }}
                    >
                      <form onSubmit={async (e) => {
                        e.preventDefault()

                        if (error) {
                          return
                        }

                        const payload = {
                          name: formValues.name,
                          email: formValues.email,
                          message: formValues.message,
                          title: formValues.title
                        }

                        try {
                          await axios.post('https://wnxhjljubi.execute-api.us-west-1.amazonaws.com/prod/api/interests', payload)

                          setFormValues({})
                          setSnackbarOpen(true)
                        } catch (error) {
                          console.error(error)
                        }

                      }}>
                        <FormControl fullWidth={true}>
                          <TextField value={formValues.name || ''} name="name" placeholder='Name' required={true} fullWidth={true} onChange={updateFormValue} />
                          <br />
                          <TextField
                            error={error}
                            helperText={error ? 'Please enter a valid email' : ''}
                            value={formValues.email || ''}
                            name="email"
                            placeholder='Email'
                            required={true}
                            fullWidth={true}
                            onBlur={handleEmailChange}
                            onChange={updateFormValue}
                          />
                          <br />
                          <TextField value={formValues.title || ''} name="title" placeholder='Title' required={true} fullWidth={true} onChange={updateFormValue} />
                          <br />
                          <TextField value={formValues.message || ''} name="message" placeholder='Message' multiline rows={4} fullWidth={true} onChange={updateFormValue} />
                          <br />
                          <LearnMoreButton />
                        </FormControl>
                      </form>
                    </div>
                  </div>
                </div>
              }
            />
          </div>

          <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={() => setSnackbarOpen(false)} severity="success">Thank you for your interest! We will reach out to you soon!</Alert>
          </Snackbar>


        </div>
        <Box
          component="footer"
          sx={{
            backgroundColor: '#2c45a3',
            py: 2,
            px: 3,
            textAlign: 'right',
          }}
        >
          <Typography variant="body2" style={{ color: 'white' }}>
            © 2023 Instant Play Design
          </Typography>
        </Box>
      </ThemeProvider>
    </div>
  )
}


const CustomDivider = () =>
  <div style={{ width: '100%', marginBottom: 30, marginTop: 30 }}>
    <Divider variant="middle" />
  </div>

const Header = ({ text }: { text: string }) =>
  <div
    style={{
      fontSize: 58,
      padding: 0,
      color: '#2c45a3',
      fontWeight: 'bold',
      textAlign: 'left',
      marginBottom: 10,
      width: '100%',
      maxWidth: 675,
    }}
  >
    {text}
  </div>

const SubText = ({ children, center }: { children: string | ReactNode, center?: boolean }) =>
  <div
    style={{
      fontSize: 28,
      ...(center ? { textAlign: 'center' } : {})
    }}
  >
    {children}
  </div>

const Section = ({ header, subtext, id }: { header: string, subtext: string | ReactNode, id?: string }) =>
  <div id={id}>
    <CustomDivider />

    <Header text={header} />


    <SubText>
      {subtext}
    </SubText>
  </div>